<template>
    <div class="feedbacks-body">
        <div class="feedbacks-body__main">
            <!-- <div class="feedbacks-body__item feedbacks-body__item--avatar">
                <avatar
                    :nickname="review.client_name"
                    :user="'client'"
                />
                <div class="feedbacks-body__item-client-name">
                    {{ review.client_name | ucfirst }}
                </div>
            </div> -->
            <div class="feedbacks-body__order">
                <div
                    class="feedbacks-body__item feedbacks-body__item--id"
                    @click="redirectToTheId(review.orderid)"
                >
                    {{ review.orderid }}
                </div>
                <div class="feedbacks-body__item feedbacks-body__item--time">
                    {{ review.created_at | moment_bidding_text }} - {{ review.created_at | moment_from }} ago
                </div>
            </div>
            <div
                class="rating"
                :class="`rating--${setStarClass}`"
            >
                <span class="rating__count"> {{ getAverageRetig }} </span>
            </div>
        </div>

        <div
            v-if="review.parsed_old && review.parsed_old.recommend_writer"
            class="feedbacks-body__recommended"
        >
            <p>Would you recommend this writer?</p>
            <p>{{ review.parsed_old.recommend_writer }}</p>
        </div>

        <div
            v-if="review.parsed_new"
            class="feedbacks-body__details feedbacks-body__details--comment"
        >
            {{ review.parsed_new.details }}
        </div>
        <div
            v-if="review.parsed_old"
            class="feedbacks-body__details feedbacks-body__details--comment"
        >
            {{ review.parsed_old.details }}
        </div>

        <div
            v-if="review.parsed_new"
            class="feedbacks-body__testimonial"
        >
            <div class="feedbacks-body__testimonial-details">
                <div class="feedbacks-body__testimonial-details-topic">
                    <div class="feedbacks-body__testimonial-details-title">
                        Delivery
                    </div>
                    <div class="feedbacks-body__testimonial-details-count">
                        <FeedbackRating
                            :rating="review.parsed_new.delivery"
                        />
                    </div>
                </div>
                <div
                    v-if="!!review.parsed_new.delivery_details"
                    class="feedbacks-body__testimonial-details-text"
                >
                    {{ review.parsed_new.delivery_details }}
                </div>
            </div>
            <div class="feedbacks-body__testimonial-details">
                <div class="feedbacks-body__testimonial-details-topic">
                    <div class="feedbacks-body__testimonial-details-title">
                        Content
                    </div>
                    <div class="feedbacks-body__testimonial-details-count">
                        <FeedbackRating
                            :rating="review.parsed_new.content"
                        />
                    </div>
                </div>
                <div
                    v-if="!!review.parsed_new.content_details"
                    class="feedbacks-body__testimonial-details-text"
                >
                    {{ review.parsed_new.content_details }}
                </div>
            </div>
            <div class="feedbacks-body__testimonial-details">
                <div class="feedbacks-body__testimonial-details-topic">
                    <div class="feedbacks-body__testimonial-details-title">
                        Communication
                    </div>
                    <div class="feedbacks-body__testimonial-details-count">
                        <FeedbackRating
                            :rating="review.parsed_new.communication"
                        />
                    </div>
                </div>
                <div
                    v-if="!!review.parsed_new.communication_details"
                    class="feedbacks-body__testimonial-details-text"
                >
                    {{ review.parsed_new.communication_details }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// import Avatar from '@/components/common/Avatar';
import FeedbackRating from '@/views/account/feedbacks/FeedbackRating.vue'
import filtersMixin from '@/mixins/filtersMixin.js';

export default {
    components: {
        // Avatar,
        FeedbackRating
    },
    mixins: [filtersMixin],
    props: {
        review: {
            type: Object,
            required: true
        }
    },
    computed: {
        getAverageRetig() {
            if (this.review?.parsed_new) {
                return Math.round((Number(this.review.parsed_new.delivery)
                + Number(this.review.parsed_new.content)
                + Number(this.review.parsed_new.communication)) / 3)
            }
            return Number(this.review.parsed_old.rate_writer)
        },
        setStarClass() {
            let className;
            if (this.getAverageRetig > 0 && this.getAverageRetig <= 3) {
                className = 'unsatisfactory'
            } else if (this.getAverageRetig > 3 && this.getAverageRetig <= 4) {
                className = 'avarage'
            } else {
                className = 'complete'
            }
            return className
        }
    },
    methods: {
        redirectToTheId(id) {
            this.$router.push({
                name: 'details',
                params: { id }
                // hash: '#details'
            })
        }
    }
}
</script>

<style scoped lang="scss">
.feedbacks-body {
    width: 100%;
    &__main{
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        @media all and (max-width: 768px) {
            flex-wrap: wrap;
        }
    }
    &__recommended {
        @media (max-width: 550px) {
            margin-top: 20px;
        }
        p:first-child {
            color: #afb2b9;
            font-size: 12px;
        }
    }
    &__order {
        display: flex;
        flex-wrap: wrap;
    }
    &__item{
        position: relative;
        white-space: nowrap;
        line-height: 70px;
        margin-right: 30px;
        @media all and (max-width: 768px) {
            font-size: 14px;
        }
        &--avatar {
            display: flex;
            align-items: baseline;
        }
        &--id{
            padding-left: 30px;
            cursor: pointer;
            &:hover {
                color: #1BB361;
            }
            &::after  {
                content: 'Order ID';
                position: absolute;
                color: #afb2b9;
                font-size: 12px;
                font-weight: 400;
                top: -20px;
                left: 0;
            }
            &::before {
                content: '';
                position: absolute;
                min-width: 24px;
                left: 0;
                top: 25px;
                width: 16px;
                height: 16px;
                mask-image: url("~@/assets/img/svg/order-id.svg");
                mask-size: contain;
                mask-repeat: no-repeat;
                background: #1976d2;
            }
        }
        &--time{
            padding-left: 30px;
            &::after {
                content: 'Created';
                position: absolute;
                color: #afb2b9;
                font-size: 12px;
                font-weight: 400;
                top: -20px;
                left: 0;
            }
            &::before {
                content: '';
                position: absolute;
                min-width: 24px;
                left: 0;
                top: 25px;
                width: 20px;
                height: 20px;
                mask-image: url("~@/assets/img/svg/clock.svg");
                mask-size: contain;
                mask-repeat: no-repeat;
                background: #1976d2;
            }
        }

    }
    &__item-client-name {
        padding: 0 25px 0 20px;
        position: relative;
        &::before {
            content: 'Client name';
            position: absolute;
            color: #afb2b9;
            font-size: 12px;
            font-weight: 400;
            top: -20px;
            left: 20px;
        }
    }
    &__details{
        margin: 40px 0;
        padding-left: 30px;
        position: relative;
        line-height: 20px;
        @media all and (max-width: 768px) {
            font-size: 14px;
        }
        &--comment {
            padding-left: 0;
            &::after {
                content: 'Client feedback';
                position: absolute;
                color: #afb2b9;
                font-size: 12px;
                font-weight: 400;
                top: -20px;
                left: 0px;
            }
        }
    }
    &__testimonial {
        display: flex;
        @media all and (max-width: 768px) {
            flex-wrap: wrap;
        }
    }
    &__testimonial-details{
        width: 31%;
        display: flex;
        flex-direction: column;
        margin-right: 3%;
        @media all and (max-width: 768px) {
            width: 100%;
            margin-bottom: 3%;
            margin-right: 0;
            &:last-child{
                margin-bottom: 0;
            }
        }
        &:last-child{
            margin-right: 0;
        }
    }
    &__testimonial-details-topic{
        display: flex;
        padding: 20px;
        background-color: #FAFAFA;
    }
    &__testimonial-details-title{
        width: 50%;
        color: #1F2939;
        font-weight: bold;
        font-size: 16px;
    }
    &__testimonial-details-count{
        width: 50%;
        text-align: right;
        font-weight: bold;
        font-size: 16px;
    }
    &__testimonial-details-text{
        font-size: 15px;
        line-height: 20px;
        padding: 0 20px 20px 20px;
        background-color: #FAFAFA;
        flex-grow: 1;
        @media all and (max-width: 768px) {
            font-size: 14px;
        }
    }
}
.rating {
    display: flex;
    flex-wrap: nowrap;
    padding: 8px 12px;
    border-radius: 4px;
    margin-right: 35px;
    @media all and (max-width: 992px) {
        margin-right: 0px;
    }
    &--unsatisfactory {
        background: url('~@/assets/img/svg/star-unsatisfactory.svg') center no-repeat;
        background-size: 20px 20px;
        background-position: 10px;
        background-color: rgba(255, 105, 12, 0.1);
        color: #FF4500;
    }

    &--avarage {
        background: url('~@/assets/img/svg/star-avarage-blue.svg') center no-repeat;
        background-size: 20px 20px;
        background-position: 10px;
        background-color: rgba(17, 97, 224, 0.1);
        color: #1161E0;
    }

    &--complete {
        background: url('~@/assets/img/svg/star-complete.svg') center no-repeat;
        background-size: 20px 20px;
        background-position: 10px;
        background-color: rgba(89, 199, 77, 0.1);
        color: #59c74d;
    }

    &__count {
        font-size: 16px;
        font-weight: bold;
        margin-left: 25px;
        padding-top: 3px;
    }
}
</style>
