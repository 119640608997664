<template>
    <div
        :class="`review--${setStarClass}`"
    >
        {{ rating }}
    </div>
</template>

<script>
export default {
    name: 'FeedbackRating',
    props: {
        rating: {
            type: String
        }
    },
    computed: {
        setStarClass() {
            let className;
            if (this.rating > 0 && this.rating <= 3) {
                className = 'unsatisfactory'
            } else if (this.rating > 3 && this.rating <= 4) {
                className = 'avarage'
            } else {
                className = 'complete'
            }
            return className
        }
    }
}
</script>

<style scoped lang="scss">
.review {
    &--unsatisfactory {
        color: #FF4500;
    }
    &--avarage {
        color: #1161E0;
    }
    &--complete {
        color: #59c74d;
    }
}
</style>
