/* eslint-disable */
import Api from '@/helpers/api/index.js'

async function getByParams(query, cache) {
    return await Api.get('/api/testimonial/list-by-params', query, { dialog: true }, cache).then((resp) => resp.data)
}

async function getByPreset(query, cache) {
    return await Api.get('/api/testimonial/list-by-preset', query, { dialog: true }, cache).then((resp) => resp.data)
}

export const getFeedbacks = async (searchObject, cache = false) => {
    const query = { ...searchObject }
    if (!query.preset) { delete query.preset }
    if (query.getType) { delete query.getType }
    if (!query.search_for) {
        delete query.search_by
        delete query.search_for
    }
    if (!query.from || !query.to) {
        delete query.from
        delete query.to
    }
    if (query.preset) {
        return await getByPreset(query, cache)
    }
    return await getByParams(query, cache)
}

export const getStatusesWithCounter = async () => {
    const presets = await Api.get('/api/testimonial/list-presets', { }, { dialog: true }, false).then((resp) => resp.data)
    const result = presets.map((item) => ({
        value: item.value,
        title: item.title,
        count: item.counters?.count
    }))
    return result
}
