<template>
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo" />
            <loading-overlay v-if="dataLoading " />
            <div class="reviews wrapper">
                <div class="my-orders">
                    <feedbacks-presets
                        :presets="searchStatusOptionsWithCount"
                        :table-info="tableInfo"
                        :loading="loading"
                        @updateSearch="onUpdateSearch"
                    />
                </div>
                <div
                    v-for="(review, index) in reviews"
                    :key="index"
                    class="reviews__cart"
                    @click.stop="showOrder(index)"
                >
                    <!-- <FeedbacksCardHeader
                        :index="index"
                        :review="review"
                        :shown-element-index="shownElementIndex"
                        @toggleOrder="toggleOrder"
                    /> -->
                    <FeedBacksCardBody
                        :index="index"
                        :review="review"
                    />
                    <!-- <div
                        class="card_header-togle card_header-togle--mobile"
                        @click.stop="toggleOrder(index)"
                    >
                        <span v-if="shownElementIndex === index">
                            Hide details
                            <chevron-up-icon />
                        </span>
                        <span v-else>
                            Show details
                            <chevron-down-icon />
                        </span>
                    </div> -->
                </div>
                <transition name="fade">
                    <div
                        v-if="!reviews.length"
                        class="feedback-not-found"
                    >
                        <not-found
                            text="You do not have any feedbacks yet."
                            desc="When you complete the order, feedback from client will be here."
                        />
                    </div>
                </transition>
                <custom-button
                    v-if="nextPageUrl"
                    default
                    class="button btn-base_colored sort-button reviews-load-more"
                    @on-btn-click="loadMore"
                >
                    Load more
                </custom-button>
            </div>
        </template>
    </main-layout>
</template>
<script>
import MainLayout from '@/layouts/MainLayout.vue'
import HeaderContent from '@/components/HeaderContent';
import NotFound from '@/components/NotFound'
// import FeedbacksCardHeader from '@/views/account/feedbacks/FeedbacksCardHeader.vue'
import FeedBacksCardBody from '@/views/account/feedbacks/FeedBacksCardBody.vue'
import LoadingOverlay from '@/components/LoadingOverlay';
import FeedbacksPresets from '@/components/account/orders/OrderPresets.vue'

import filtersMixin from '@/mixins/filtersMixin.js';
import { eventBus } from '@/helpers/event-bus';

// import ChevronUpIcon from 'mdi-vue/ChevronUp';
// import ChevronDownIcon from 'mdi-vue/ChevronDown';
import {
    getFeedbacks, getStatusesWithCounter
} from '@/services/feedbacks/feedbackService.js'

const defaultQuery = {
    page: 1,
    preset: '',
    per_page: 10
}
export default {
    components: {
        MainLayout,
        HeaderContent,
        NotFound,
        // FeedbacksCardHeader,
        FeedBacksCardBody,
        LoadingOverlay,
        FeedbacksPresets
        // ChevronUpIcon,
        // ChevronDownIcon
    },
    metaInfo: {
        title: 'Reviews'
    },
    mixins: [filtersMixin],
    data() {
        return {
            shownElementIndex: -1,
            reviews: null,
            reviewsList: [],
            lastPage: null,
            searchStatusOptionsWithCount: [],
            feedbacksCounters: null,
            // perPage: null,
            page: 1,
            dataLoading: null,
            headerInfo: {
                title: 'Сustomer Reviews',
                className: 'header-content__bookmarks',
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    }
                ]
            }
        }
    },
    computed: {
        tableInfo() {
            return [
                {
                    title: 'Count',
                    count: this.feedbacksCounters?.count
                }
            ]
        },
        nextPageUrl() {
            return this.reviewsList?.next_page_url
        }
    },
    async created() {
        this.searchObject = {
            ...this.searchObject,
            ...this.$route.query,
            page: 1,
            per_page: +this.$route.query.per_page || 10
        }
        this.routerReplacer()
        await this.getData()
    },
    methods: {
        async getData(cache = false, with_total = true) {
            try {
                this.loading = true
                const requestArr = [getFeedbacks({ ...this.searchObject, mode: 'list' }, cache), getStatusesWithCounter()]
                if (with_total) {
                    requestArr.push(getFeedbacks({ ...this.searchObject, mode: 'count' }, cache))
                }
                const allQuery = await Promise.all(requestArr)
                // eslint-disable-next-line prefer-destructuring
                this.reviewsList = allQuery[0]
                // eslint-disable-next-line prefer-destructuring
                this.searchStatusOptionsWithCount = allQuery[1]
                // eslint-disable-next-line prefer-destructuring
                this.feedbacksCounters = allQuery[2]
                this.reviews = this.reviewsList.data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        incrementPage() {
            this.searchObject.page += 1
        },
        async onUpdateSearch({ query }) {
            this.searchObject = { ...defaultQuery, ...query }
            this.searchObject.page = 1
            await this.getData()
            this.routerReplacer()
        },
        toggleOrder(index) {
            this.shownElementIndex === index
                ? this.shownElementIndex = -1
                : this.shownElementIndex = index
        },
        showOrder(index) {
            if (this.shownElementIndex !== index) {
                this.shownElementIndex = index
            }
        },
        routerReplacer() {
            if (JSON.stringify(this.searchObject) !== JSON.stringify(this.$route.query)) {
                this.$router.replace({ query: { ...this.searchObject } })
            }
        },
        async loadMore() {
            this.loading = true
            this.incrementPage()
            const { data, next_page_url } = await getFeedbacks({ ...this.searchObject, mode: 'list' }, false)
            this.reviews = [...this.reviews, ...data]
            this.reviewsList.next_page_url = next_page_url
            this.routerReplacer()
            this.loading = false
        }
    }
}
</script>

<style scoped lang="scss">

.reviews{
    margin-top: -20px;
    &__cart {
        padding: 20px;
        margin-bottom: 20px;
        background: white;
        box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
        transition: 0.5s;
        &:hover{
            box-shadow: 0 5px 30px rgb(0 0 0 / 20%);
        }
    }
}

.feedback-not-found {
    margin: 100px 0;
}

.card_header-togle--mobile{
    margin: 30px 0px -10px;
}

.reviews-load-more{
    margin: 0 auto;
}
</style>
